import AmplitudeLogger from './wrappers/amplitude';
import ConsoleLogger from './wrappers/console';
import { Event, } from './types';
class Logger {
    config;
    loggers;
    loggerFunctions;
    constructor(config, loggers = []) {
        this.config = config;
        this.loggers = loggers;
        this.config = config;
        this.loggerFunctions = this.buildLoggers(loggers);
    }
    buildLoggers(loggers) {
        if (!loggers.length) {
            return [new ConsoleLogger()];
        }
        return loggers.map(({ name, options }) => {
            switch (name) {
                case 'amplitude':
                    return new AmplitudeLogger(options);
                case 'console':
                default:
                    return new ConsoleLogger();
            }
        });
    }
    logEngagement(event, props, callback) {
        for (const logger of this.loggerFunctions) {
            logger.logEngagement({
                ...this.config,
                ...props,
                context_value: props.context_value?.toString() ?? '',
                element: props.element || '',
                event,
                timestamp: new Date().toISOString(),
                title: props.title || '',
            }, callback);
        }
    }
    logError = (props, callback) => this.logEngagement(Event.ERROR, props, callback);
    logState = (props, callback) => this.logEngagement(Event.STATE, props, callback);
    logUserInteraction = (props, callback) => this.logEngagement(Event.USER_INTERACTION, props, callback);
}
export { Logger };
