export const nameSort = (dataItems) => {
    return [...dataItems].sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            usage: 'sort',
            sensitivity: 'base',
            ignorePunctuation: false,
        });
    });
};
export const externalTitleSort = (dataItems) => {
    return [...dataItems].sort((a, b) => {
        return a.title.localeCompare(b.title, undefined, {
            numeric: true,
            usage: 'sort',
            sensitivity: 'base',
            ignorePunctuation: false,
        });
    });
};
