import deepmerge from 'deepmerge';
var Environments;
(function (Environments) {
    Environments["LOCAL"] = "local";
    Environments["DEVELOPMENT"] = "development";
    Environments["STAGING"] = "staging";
    Environments["PRODUCTION"] = "production";
})(Environments || (Environments = {}));
var BaseConfigKeys;
(function (BaseConfigKeys) {
    BaseConfigKeys["CDN_BASE_URL"] = "CDN_BASE_URL";
    BaseConfigKeys["CDN_BASE_URL_ALT"] = "CDN_BASE_URL_ALT";
    BaseConfigKeys["TELEMETRY_ENV_NAME"] = "TELEMETRY_ENV_NAME";
})(BaseConfigKeys || (BaseConfigKeys = {}));
const config = {
    [Environments.LOCAL]: {
        [BaseConfigKeys.CDN_BASE_URL]: 'https://dovetail.pv-platforma.xyz',
        [BaseConfigKeys.CDN_BASE_URL_ALT]: 'https://dovetail.platforma-dev.io',
        [BaseConfigKeys.TELEMETRY_ENV_NAME]: 'dev',
    },
    [Environments.DEVELOPMENT]: {
        [BaseConfigKeys.CDN_BASE_URL]: 'https://dovetail.pv-platforma.xyz',
        [BaseConfigKeys.CDN_BASE_URL_ALT]: 'https://dovetail.platforma-dev.io',
        [BaseConfigKeys.TELEMETRY_ENV_NAME]: 'dev',
    },
    [Environments.STAGING]: {
        [BaseConfigKeys.CDN_BASE_URL]: 'https://dovetail.pv-platforma.biz',
        [BaseConfigKeys.CDN_BASE_URL_ALT]: 'https://dovetail.platforma-staging.io',
        [BaseConfigKeys.TELEMETRY_ENV_NAME]: 'staging',
    },
    [Environments.PRODUCTION]: {
        [BaseConfigKeys.CDN_BASE_URL]: 'https://dovetail.platforma.planview.com',
        [BaseConfigKeys.CDN_BASE_URL_ALT]: '',
        [BaseConfigKeys.TELEMETRY_ENV_NAME]: 'prod',
    },
};
const domainToEnvironment = {
    [config[Environments.LOCAL].CDN_BASE_URL]: Environments.DEVELOPMENT,
    [config[Environments.DEVELOPMENT].CDN_BASE_URL]: Environments.DEVELOPMENT,
    [config[Environments.DEVELOPMENT].CDN_BASE_URL_ALT]: Environments.DEVELOPMENT,
    [config[Environments.STAGING].CDN_BASE_URL]: Environments.STAGING,
    [config[Environments.STAGING].CDN_BASE_URL_ALT]: Environments.STAGING,
    [config[Environments.PRODUCTION].CDN_BASE_URL]: Environments.PRODUCTION,
};
class Environment {
    static instance;
    static getInstance() {
        if (!Environment.instance) {
            Environment.instance = new Environment();
            Environment.instance.getEnvironment();
        }
        return Environment.instance;
    }
    environment = Environments.LOCAL;
    getEnvironment = () => {
        const scripts = document.getElementsByTagName('script');
        for (const script of scripts) {
            if (script.src.startsWith('https://dovetail')) {
                // Example script.src: https://dovetail.platforma.planview.com/dist/OKR.min.js
                const [domain] = script.src.split('/dist/');
                Environment.instance.environment = domainToEnvironment[domain];
                break;
            }
        }
    };
    getConfig(projectConfig) {
        const mergedConfig = deepmerge(config, projectConfig ?? {});
        return mergedConfig[Environment.instance.environment];
    }
}
const EnvironmentConfig = Environment.getInstance();
const TelemetryURLsUS = {
    'dovetail-okr-dev': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV1T4_mf25HWxCqZgyFpIgcLY2kbRqynh-f7ZXZ1jgNuW1aVFIOv3Metmq8OH9bYuCpU5h5cKDTiQ8pD_FJXMbGfRQxO9lA1VhbMgJx8O-Wf4g==',
    'dovetail-okr-staging': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3tXIq5eH_Z4LXxrsN66ptxushheM1U9mzWNE424xgDNMa-vWUkKPbHPm2rE3mwYDNkmH57jNHqu1VibaFjWI4Ccny1MRR8LzjJP7TvBJBIEQ==',
    'dovetail-okr-prod': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2v9xkJML2hUEA0JRW_yAF-CF5xzrMek_SYKR40r1wvQTpA8t-9UX5r9wn3q-NFEEViwa6AoTdRccJpud70CY8ijSSHyB0pR4dAld_Zy8XXfQ==',
    'dovetail-teamtap-dev': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2ZGHtuvyE4lndevmVyxdOLZlIh3yW0prpDllVwMNIy0yvBSf6kP41XtVXk4bpmr5zhSITmjjrvqKV7XQ6SKRp6OuhczIcpQxsnXQBV_jOD2g==',
    'dovetail-teamtap-staging': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3lzlhOhWL7nZnCFrwlZ4y_1lgL63MxyRIjvWvxr1VUgGqw8KN6VwKfFFOJD7qTGVzcMKay1OleUBE5TeMaQgEkpH91_00UtyaKslQi6u0iTw==',
    'dovetail-teamtap-prod': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2NM3xM0BENeN6dGAFah_pPHK_ll2d2dauMgS-3rzODuljo3bstjjOGmorAyo3m022fvS92vmi7XiHK4T2P-9rIgCVIJZWrZO6PqPFNElBSeA==',
    'dovetail-roadmap-dev': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV05n4FpMif-UccARAqao6lAa_p2Cz1OhPB5yOUJxlnPsorL4VneNdOH9Hqmz8bNLB0tHfCJJOLPEjkjyJchRA7kuzMpYvmPvicuN15hyuLw3A==',
    'dovetail-roadmap-staging': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2qix6pAMHgr1SgFNLihBkw7y3MAlUyU7brIEevL-HNb4y06yN4P1vKgYxrwQkMCiY8KUNMl_UJj-o-uWdBs3q6kpOD0DZOEJI4lU74sWWmEg==',
    'dovetail-roadmap-prod': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3v-MV3GNKEopnWR9jXFqHfMrQVr77cAB_SYp0vo-6KwtBdbxewbRHSUSFscI7hWNr8UcFm0rXoIKIzD3Lej4xR3k0Kt7h4FB2yot7Etdxufg==',
    'dovetail-notification-dev': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2RP7fLikyzmRseQ1QORNartE4cm9BtGWUPIx5kLnjlsGdrxWhHtb6oNxHItfNZHwup3RNQ_kKZFPX-0nC4oiDEQcYjjvAeTHaXO61ucG_iVg==',
    'dovetail-notification-staging': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV1AeN9gQJ9gQeivLcMqTXL4XulDS8La_Aqi2O8p5PBD0b21rQ14JVm0SUdzA1UxQiSWbPEEE2D-c18WpAz1hN70nsF88IzPGfGwt8BI-iL1Lg==',
    'dovetail-notification-prod': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV16oiUXQ6CQsgYIY-Knt5AHfwNX2YeZL7Nko8o9TM4ro1_bzi6Ag2LoaVlXgfHX9-rZNFCYZViPt9CuwIPoS95pspJabzY7IU4-6Y93UhyDhQ==',
    'dovetail-productswitcher-dev': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2XDBzEONoH1RnrhEjy_WQNLDYfrgLto34LooqTIhS8BQJRelDZwAjHK0BCa2Tc8VmA735v8EHJZd5muW8FnXZjG4EZLNmKi2uxbcAPp83oNA==',
    'dovetail-productswitcher-staging': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3lgF_ir4gpOCmfPrz8Dje1yaV5tV_AXZ0efC8-MNGU1wfJVRnCgD8xuqZvp6tXW6cQJwPcPCBL8HLoodWsJzM8NRZxyUAoIZHfPs981MmK3A==',
    'dovetail-productswitcher-prod': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3o4DHVDUVxZ_MlDpouAuEK4OxaMFX0fcerhMVlp7Uflk1AyIYIgHvAxQ9pgyy-0uhSsEf3mzwDwjRpclXPopiOSD5VsHumZDbHZvjJpxJU9g==',
    'dovetail-whiteboard-dev': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV1S_Pue4IllopIendk6Ut7n9Vb2m3TM3vYU9UdK8g1u1CbnTunjbgzNbLMUFranK8CyOO29NfNSAOZXdxjnH23EvcBGDM9Pi9aennUu2wN9RA==',
    'dovetail-whiteboard-staging': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3wYV4GsHuNwrMO2rHtKX3CVCnMwOVn2gPMQjyGLclxWnxwdOoZdS--qpzA4ZtWMq2H9gOUvnh4CQ0BXRpTxh-Vff7ze5xPSs96ZO02o4wfDw==',
    'dovetail-whiteboard-prod': 'https://rum-collectors.us2.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3QKX9WI_5MKWIYgyN8_9aERCKCdam5TLZxH7ohwoCgLljfDeKBFZuAmb59cRSVsYUSS2DLC-lp0_WnQTgN4oIzwqNbq6ceoGMj_dI_k4kQxA==',
};
const TelemetryURLsEU = {
    'dovetail-okr-prod': 'https://rum-collectors.de.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3btW66AxVKR8oGWXHp0jUb3FV6E6gyOlzH5Ds84JejiYczv91n-V_gD4IWyyPpVKHkauT9V69xedGipo0J0oG4yg1iBC520_JKwGCYH1HoGQ==',
    'dovetail-teamtap-prod': 'https://rum-collectors.de.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2x8YO_ubcPXgfqunkGGCTp7WVLZ4bOFiVAseHuBTy9xhFGmHjs3vWP70BdnyyAspLCAU8yaMbjG1tcjNXchBRGkZkLO-7mqSz26FOUymmMPg==',
    'dovetail-notification-prod': 'https://rum-collectors.de.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2pC_wjTQ4Xl_jaWsFlWZFylAqH54FGVMOPskOB8p-VEkD1vDQlYekT-WRmjiyiIQWYSkUKz4R5HANTDBPjgLfgpWU-6Ps0KVuiHYLSDRW9wQ==',
    'dovetail-productswitcher-prod': 'https://rum-collectors.de.sumologic.com/receiver/v1/rum/ZaVnC4dhaV0KeLo7HGS69Tlr0bWpdwKuQdT_ydXFKZyFv96BA4HEFw_DPVcjs8h2uP3eOVbAt1ZdSlTQ1d9beo_PZyDtqdZ3O88AK09ZHBn5uwvtZP6r9A==',
    'dovetail-whiteboard-prod': 'https://rum-collectors.de.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3om8iAYVEmpN0-IAVvHZt4wDaTOP8EKdYcGsmJH9IVrGf3pe8uaXInsdiZi5JYQ9ANVekDtbUAX7k6XEdOkX6wMk2PznUinYm7sp7MpyPxfw==',
};
const TelemetryURLsAP = {
    'dovetail-okr-prod': 'https://rum-collectors.au.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3dbzw48eWrrRdeL46E8w7Jsa9KsieXPlKdLaEefoVWR9JSR6vJL0M3VJrqL9i-3HTuH67lfDMpA27IGOyxEfRXy13-tdVC2wyWF1wB-VxR2w==',
    'dovetail-teamtap-prod': 'https://rum-collectors.au.sumologic.com/receiver/v1/rum/ZaVnC4dhaV00xwu_zEPyHAZDRbM5PpdhFQFCIvMFVbIJ6e0BpElNnHbrwmxTZP_vvAppFoRcS9QkLnNultDEoeHHky5km1y3mswkuW_ewBipNdVUYcKeWw==',
    'dovetail-roadmap-prod': 'https://rum-collectors.au.sumologic.com/receiver/v1/rum/ZaVnC4dhaV3eeH0pcgSmS9drW6LTj9L2movWqlwUlHOhixAH2KJXbcZZuXgGD0YpX97ZXRnrkHcT6ndUc7k7EL5u-Z4_JWotojMFi5BVXQKzaoJTpa7_Xg==',
    'dovetail-notification-prod': 'https://rum-collectors.au.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2_0g6-9T_rPrEQNxBYCqwJL-qxQq8ijjHoIOFdjwgtCoTEd_BlsrsabOSIp7iRFGi3MDh_7St2s2z2-Le2g-8OlN5tO6_5JrjEeGuEPp09Ww==',
    'dovetail-productswitcher-prod': 'https://rum-collectors.au.sumologic.com/receiver/v1/rum/ZaVnC4dhaV0tAbSdyTObANJtBPRqfU77pOr3iTg5zhpCDeXatBKKzzK8N3Kq3FOMo085eLMSsSusLStvPgzG7YnhgeVRA5C8pBKbCZ0upJlSbrB84nIsWQ==',
    'dovetail-whiteboard-prod': 'https://rum-collectors.au.sumologic.com/receiver/v1/rum/ZaVnC4dhaV2cmjUcC1jtTp9YC02hH2VLXshh0srWxxgh3X6QTKPobt2ETHGP5bJKxHOUZjCSC42gQXFC_f30343j86AQ__4xu_h7SBZmnsAxZsBAMHx5DQ==',
};
export { Environments, EnvironmentConfig, TelemetryURLsUS, TelemetryURLsEU, TelemetryURLsAP, };
