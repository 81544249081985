import { createContext } from 'react';
import { Product } from '../types';
export const ConfigContext = createContext({
    logger: {
        logError: () => { },
        logState: () => { },
        logUserInteraction: () => { },
    },
    product: Product.PLATFORMA,
});
