import { createRoot } from 'react-dom/client';
const rootMap = new WeakMap();
const createOrGetRoot = (element) => {
    let existingRoot = rootMap.get(element);
    if (!existingRoot) {
        existingRoot = createRoot(element);
        rootMap.set(element, existingRoot);
    }
    return existingRoot;
};
const unmountRoot = (element) => {
    const existingRoot = rootMap.get(element);
    if (existingRoot) {
        existingRoot.unmount();
        rootMap.delete(element);
    }
};
export { createOrGetRoot, unmountRoot };
