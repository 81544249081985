const amplitudeTrackingOptions = {
    city: false,
    country: false,
    carrier: false,
    device_manufacturer: false,
    device_model: false,
    dma: false,
    ip_address: false,
    language: true,
    os_name: true,
    os_version: true,
    platform: false,
    region: false,
    version_name: false,
};
var Event;
(function (Event) {
    Event["ERROR"] = "error";
    Event["STATE"] = "state";
    Event["USER_INTERACTION"] = "user_interaction";
})(Event || (Event = {}));
export { Event, amplitudeTrackingOptions, };
