import * as uuid from 'uuid';
import { Product } from '../types';
const uuid4 = () => {
    return uuid.v4();
};
const externalTypeURLMap = {
    [Product.LEANKIT]: '.leankit.',
};
const hostDomain = process.env.HOST_APP_DOMAIN || window.location.hostname;
const getHostnameFromDomain = (externalType) => hostDomain.split(externalTypeURLMap[externalType])[0] || Product.PLATFORMA;
export { uuid4, getHostnameFromDomain, hostDomain };
