// this is a clone of the supportedLocales.js file but we were not able to use the
// @dovetail/core import in hooks.ts without creating an actual .ts file;  we tried
// adding the type definition to types/index.d.ts but the linting still failed so
// we are using a dual-file approach for now as a workaround
export const supportedLocales = [
    'de',
    'de-DE',
    'en',
    'en-CA',
    'en-GB',
    'en-US',
    'es',
    'es-ES',
    'fr',
    'fr-CA',
    'fr-FR',
];
