import semver from 'semver/preload';
export const minSoftwareVersionE1PrmPhase1 = '2023.12.0';
// The min software version for phase 2 needs to be 2024.3.0
export const minSoftwareVersionE1PrmPhase2 = '2024.3.0';
/**
 * This assumes the format of <yyyy.M.patchVersion#> is constant for all applications. If it is not then
 * this will have to be a e1_prm specific method.
 * @param currentSoftwareVersion
 * @param minSoftwareVersion
 */
export const isValidSoftwareVersion = (currentSoftwareVersion, minSoftwareVersion) => {
    return semver.gte(currentSoftwareVersion, minSoftwareVersion);
};
