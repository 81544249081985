import Amplitude from 'amplitude-js';
import { amplitudeTrackingOptions, } from '../types';
export default class AmplitudeLogger {
    options;
    amplitudeInstance;
    constructor(options) {
        this.options = options;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { amplitudeConfig, amplitudeInstanceName, apiKey, onInit, userId } = options;
        if (apiKey) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            const ampInstance = Amplitude.getInstance(amplitudeInstanceName);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            ampInstance.init(apiKey, userId, {
                ...amplitudeConfig,
                trackingOptions: amplitudeTrackingOptions,
            }, (client) => {
                onInit && onInit(client);
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        this.amplitudeInstance = Amplitude.getInstance(amplitudeInstanceName);
    }
    logEngagement(props, callback) {
        const { title, ...restProps } = props;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        this.amplitudeInstance.logEvent(title, {
            ...restProps,
        }, callback);
    }
}
