import React, { createContext, useEffect } from 'react';
import { initialize } from '@sumologic/opentelemetry-rum';
import { EnvironmentConfig, TelemetryURLsAP, TelemetryURLsEU, TelemetryURLsUS, } from '../environment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getRegionFromToken } from './auth';
class ErrorBoundary extends React.Component {
    render() {
        return this.props.children;
    }
}
function configureTelemetry(telemetryProps) {
    // initialize sumo logic open-telemetry
    const { TELEMETRY_ENV_NAME } = EnvironmentConfig.getConfig();
    const telemetryServiceName = 'dovetail-' + telemetryProps.project + '-' + TELEMETRY_ENV_NAME;
    const TelemetryURLs = telemetryProps.region == 'US'
        ? TelemetryURLsUS
        : telemetryProps.region === 'EU'
            ? TelemetryURLsEU
            : TelemetryURLsAP;
    return initialize({
        collectionSourceUrl: TelemetryURLs[telemetryServiceName],
        serviceName: telemetryServiceName,
        applicationName: telemetryProps.project,
        samplingProbability: 0.1,
        collectErrors: true,
    });
}
const captureException = (sumologic, error, props) => {
    sumologic?.recordError(error?.message, props);
};
const captureMessage = (sumologic, message) => {
    // will only be there if telemetry is setup
    const tracer = sumologic?.tracer;
    const span = tracer.startSpan(message + ' sending');
    span.end();
};
export const TelemetryContext = createContext({
    captureException: () => {
        console.log('Cannot use captureException without a TelemetryProvider');
    },
    captureMessage: () => {
        console.log('Cannot use captureMessage without a TelemetryProvider');
    },
});
const TelemetryProvider = ({ children, product, project, hostingMethod, container_id, user_id, token, }) => {
    const { enableTelemetry } = useFlags();
    const region = getRegionFromToken(token);
    const telemetry = React.useRef(null);
    if (!telemetry.current && enableTelemetry) {
        telemetry.current = configureTelemetry({
            containerId: container_id,
            userId: user_id,
            product: product,
            project: project,
            hostingMethod: hostingMethod,
            enablePerformance: true,
            region: region,
        });
    }
    const value = React.useMemo(() => {
        if (enableTelemetry && telemetry.current) {
            return {
                captureMessage: captureMessage.bind(null, telemetry.current),
                captureException: captureException.bind(null, telemetry.current),
            };
        }
        else {
            return {
                captureMessage: () => { },
                captureException: () => { },
            };
        }
    }, [enableTelemetry]);
    useEffect(() => {
        if (!telemetry.current || !enableTelemetry) {
            return;
        }
        const api = telemetry.current.api;
        return () => {
            api.context.disable();
            api.diag.disable();
            api.metrics.disable();
            api.propagation.disable();
            api.trace.disable();
            telemetry.current = null;
        };
    }, [enableTelemetry]);
    return (<TelemetryContext.Provider value={value}>
      {children}
    </TelemetryContext.Provider>);
};
const useCaptureException = () => {
    const { captureException } = React.useContext(TelemetryContext);
    return captureException;
};
const useCaptureMessage = () => {
    const { captureMessage } = React.useContext(TelemetryContext);
    return captureMessage;
};
export { ErrorBoundary, TelemetryProvider, useCaptureException, useCaptureMessage, };
