import { Product, Themes } from '../types';
import { validateEnum, validateProps } from './validateProps';
const componentFactory = (getComponentModule, config) => function ({ element, theme = config.theme || Themes.default, }) {
    if (!element) {
        throw new Error(`Cannot inject because provided element was not found.`);
    }
    validateProps({ product: config.product, theme }, { product: 'string', theme: 'string' });
    validateEnum({ product: config.product, theme }, { product: Product, theme: Themes });
    let componentInstanceArgs = { ...config, element, theme };
    return {
        render: async function (props) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { default: component } = (await getComponentModule());
            componentInstanceArgs = {
                ...componentInstanceArgs,
                ...props,
            };
            element.style.overflow = 'hidden';
            return component(componentInstanceArgs);
        },
        unmount: async () => {
            const { unmountComponent } = await import(
            /* webpackChunkName: "unmountComponent" */ '.');
            unmountComponent(element);
        },
    };
};
export default componentFactory;
