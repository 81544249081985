import { Mutex } from 'async-mutex';
import { jwtDecode } from 'jwt-decode';
const buildTokenKey = (product) => {
    return `${product}_platformaToken`;
};
const clientLock = new Mutex();
const getToken = async (product, apiToken) => {
    let token = 'none';
    if (typeof apiToken === 'string') {
        token = apiToken;
    }
    else if (apiToken && typeof apiToken === 'function') {
        const tokenKey = buildTokenKey(product);
        // get release first, then check localStorage after to catch the stored result
        // of any outstanding auth calls already running
        const release = await clientLock.acquire();
        try {
            token = localStorage.getItem(tokenKey) || 'none';
            if (token === 'none' || isTokenExpired(token)) {
                let jwtToken = await apiToken();
                token = jwtToken.idToken || jwtToken.token || 'none';
                // if returned apiToken is expired, try it one more time...
                if (isTokenExpired(token)) {
                    jwtToken = await apiToken();
                    token = jwtToken.idToken || jwtToken.token || 'none';
                }
                localStorage.setItem(tokenKey, token);
            }
        }
        finally {
            release();
        }
    }
    // finally, check for invalid/expired token
    if (!token || isTokenExpired(token)) {
        throw new Error('Authentication Failure: The passed in promise returns an expired token.');
    }
    return token;
};
const removeAuthToken = (product) => {
    const tokenKey = buildTokenKey(product);
    localStorage.removeItem(tokenKey);
};
const isTokenExpired = (token) => {
    const { exp } = jwtDecode(token);
    // if the expiration is in 20 seconds or less, refresh
    // in order to give a buffer for potential latency
    return exp - Date.now() / 1000 <= 20;
};
const getRegionFromToken = (token) => {
    const iss = token && token.length > 0 ? jwtDecode(token).iss : '';
    return getRegion(iss);
};
const getRegion = (value) => {
    const regions = new Map([
        ['-ap-', 'AP'],
        ['-eu-', 'EU'],
        ['-us-', 'US'],
    ]);
    for (const key of Object.keys(regions)) {
        if (value.includes(key)) {
            return regions.get(key) || 'US';
        }
    }
    return 'US';
};
const getAuthHeaders = async (product, apiToken) => {
    const token = await getToken(product, apiToken || '');
    if (token) {
        return {
            authorization: `Bearer ${token}`,
        };
    }
    throw new Error('Authentication Failure: No token was passed in or it could not be retrieved.');
};
// This session storage key is set by dovtail-standalone
// before redirecting to PV Admin for auth.  Standalone dovetail
// apps may need to render a loading state when this is true to
// prevent errors being displayed to the user as the page tries
// to load PVAdmin
const isInStandaloneOAuthFlow = () => {
    return !!sessionStorage.getItem('isInOAuthFlow');
};
export { getAuthHeaders, getToken, removeAuthToken, buildTokenKey, getRegionFromToken, isInStandaloneOAuthFlow, };
