export const getItemFromLocalStorage = (key) => window.localStorage.getItem(key) || null;
export const setItemInLocalStorage = (key, value) => {
    try {
        window.localStorage.setItem(key, value);
    }
    catch (err) {
        // Usually happens if a quota has been exceeded
        console.error('Error setting item in localStorage', err);
    }
};
export const setJsonInLocalStorage = (key, value) => {
    const json = JSON.stringify(value);
    setItemInLocalStorage(key, json);
};
export const getJsonFromLocalStorage = (key) => {
    const json = getItemFromLocalStorage(key);
    try {
        return JSON.parse(json);
    }
    catch {
        console.error('Error in getJsonFromLocalStorage: Could not parse JSON');
        return null;
    }
};
